<template>


  <div>
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <h4>อัพโหลดรูปภาพ </h4>
          <br>
          <label> หมายเหตุ </label>
          <div class="mt-4">
          <vs-input class="inputx" placeholder="หมายเหตุ (ไม่จำเป็นต้องมี)" v-model="info" name="info"/>
          </div>
          <br>
          <label>สถานะแสดงหน้าเว็บ</label>
        <vs-select  class="mt-4" v-model="status" >
                <vs-select-item  :key="index" :value="item.value" :text="item.text" v-for="(item,index) in datagroups" />
        </vs-select>
        <br>
          <label> อัพรูปรีวิว COPY & PASTE </label>
          <div class="mt-4">
          <vs-input class="inputx" placeholder="กรุณาวางรูป" :value="selectedFile.name" @paste="onPaste"/>
          <vs-checkbox disabled="true" class="mt-4" :color="chk_box.color" v-model="chk_box.data">{{chk_box.text}}</vs-checkbox>
          <canvas  style="border:1px solid grey;" id="mycanvas" width="100" height="100" ></canvas>
          </div>

          <vs-button color="success" type="relief" class="mt-8" @click="uploadFile">อัพรูป</vs-button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {Validator} from 'vee-validate'
import axios from '../../axios'


const dict = {
  custom: {
    username: {
      required: 'กรุณากรอกข้อมูล',
      alpha_num: 'ตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น'
    },
    amount: {
      required: 'กรุณากรอกข้อมูล',
      decimal: 'ต้องเป็นตัวเลขหรือจุดทศนิยมเท่านั้น'
    },
    info: {
      required: 'กรุณากรอกข้อมูล'
    }
  }
}
// register custom messages
Validator.localize('en', dict)

export default {
  data () {
    return {
      info: '',
      status: true,
      resUpload: {},
      selectedFile: [],
      chk_box: {text: 'กรุณาวางรูป', color: 'danger', data: false},
      datagroups: [
        {
          text: 'แสดงหน้าเว็บ',
          value: true
        },
        {
          text: 'ไม่แสดงหน้าเว็บ',
          value: false
        }
      ]
    }
  },
  computed: {},
  watch: {
    selectedFile () {
      if (this.selectedFile.name.length > 0) {
        this.chk_box.text = 'วางรูปสำเร็จ'
        this.chk_box.color = 'success'
        this.chk_box.data = true
      } else {
        this.chk_box.text = 'วางรูปไม่สำเร็จ'
        this.chk_box.color = 'danger'
        this.chk_box.data = false
      }
      if (this.selectedFile.length === 0) {
        this.chk_box.text = 'วางรูปไม่สำเร็จ'
        this.chk_box.color = 'danger'
        this.chk_box.data = false
      }
    }
  },
  methods: {
    async uploadFile () {
      await this.onUploadFile2()
    },
    onFileChange (e) {
      const selectedFile = e.target.files[0]
      this.selectedFile = selectedFile
    },
    async  onUploadFile2 () {
      const formData = new FormData()
      formData.append('file', this.selectedFile)
      formData.append('info', this.info)
      formData.append('status', this.status)
      // sending file to the backend
      await axios
        .post('review/upload', formData)
        .then(response => (this.resUpload = response.data))
      if (this.resUpload.status === true) {
        // console.log('this.resUpload', this.resUpload)

        // setTimeout(() => {
        //   window.location.reload()
        // }, 1500)
        await this.$vs.notify({
          time: 10000,
          color: 'success',
          position: 'top-right',
          icon: 'error',
          title: this.resUpload.info.title,
          text: this.resUpload.info.info
        })
        this.info = ''
        this.status = true
        this.selectedFile = []
      } else {
        this.$vs.notify({
          time: 10000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: this.resUpload.info.title,
          text: this.resUpload.info.info
        })
      }
    },
    async  onUploadFile () {
      const formData = new FormData()
      formData.append('file', this.selectedFile)
      formData.append('topupid', this.agent_error.data.topup_id)  // appending file

      // sending file to the backend
      await axios
        .post('upload/upload', formData)
        .then(
        //   res => {
        //   console.log(res)
        // }
        )
        .catch(
        //   err => {
        //   console.log(err)
        // }
        )
    },
    retrieveImageFromClipboardAsBlob (pasteEvent, callback) {
      if (pasteEvent.clipboardData === false) {
        if (typeof (callback) === 'function') {
          callback(undefined)
        }
      }

      const items = pasteEvent.clipboardData.items

      if (items === undefined) {
        if (typeof (callback) === 'function') {
          callback(undefined)
        }
      }

      for (let i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf('image') === -1) continue
        // Retrieve image on clipboard as blob
        const blob = items[i].getAsFile()

        if (typeof (callback) === 'function') {
          callback(blob)
          this.selectedFile = blob
        }
      }
    },
    onPaste (evt) {
      // console.log('on paste', evt)
      // Handle the event
      this.retrieveImageFromClipboardAsBlob(evt, function (imageBlob) {
        // If there's an image, display it in the canvas
        if (imageBlob) {
          const canvas = document.getElementById('mycanvas')
          const ctx = canvas.getContext('2d')

          // Create an image to render the blob on the canvas
          const img = new Image()

          // Once the image loads, render the img on the canvas
          img.onload = function () {
            // Update dimensions of the canvas with the dimensions of the image


            // Draw the resized image
            const resizedWidth = 300 // Adjust the resized width as needed
            const resizedHeight = (img.height / img.width) * resizedWidth

            canvas.width = resizedWidth
            canvas.height = resizedHeight

            ctx.drawImage(img, 0, 0, resizedWidth, resizedHeight)
            // Draw the image


            // Display a smaller version of the image
            const thumbnailCanvas = document.getElementById('thumbnailCanvas')
            const thumbnailCtx = thumbnailCanvas.getContext('2d')

            const thumbnailSize = 100 // Adjust the size as needed
            thumbnailCanvas.width = thumbnailSize
            thumbnailCanvas.height = thumbnailSize
            thumbnailCtx.drawImage(img, 0, 0, img.width, img.height, 0, 0, thumbnailSize, thumbnailSize)
          }

          // Crossbrowser support for URL
          const URLObj = window.URL || window.webkitURL

          // Creates a DOMString containing a URL representing the object given in the parameter
          // namely the original Blob
          img.src = URLObj.createObjectURL(imageBlob)
        }
      })
    }

  }
}

</script>
